<template>
    <BT-Blade-Item
        :bladesData="bladesData"
        bladeName="route"
        :canEdit="false"
        :canMinimize="false"
        :canPin="false"
        hideBackButton
        :loadingMsg="loadingMsg"
        navigation="routes"
        :onPullSuccessAsync="pullRoute"
        startEditing
        title="Route"
        :actualUsedHeight="112"
        :defaultBladeWidth="350">
        <template v-slot="{ item }">
        
            <BT-Field-String
                v-model="item.routeName"
                label="Route Name"
                isEditing />

            <v-divider class="my-2" />

            <BT-Field-Checkbox
                v-model="item.canBundle"
                label="Can Bundle Together"
                isEditing />

            <BT-Field-Checkbox
                v-model="item.isRestrictedBundling"
                label="Only Bundle Complete Matches"
                isEditing />
            
            <BT-Field-String
                v-model="item.bundleName"
                label="Bundle Name"
                isEditing />

            <v-divider class="my-2" />

            <v-expansion-panels v-model="panelV" multiple>
                <BT-Blade-Expansion-Items
                    :canRefresh="false"
                    :canExportCSV="false"
                    :canSelect="false"
                    :dense="false"
                    hideActions
                    :items="item.movements"
                    showList
                    title="Route"
                    @select="selectMovement">
                    <template v-slot:actions="{ open }">
                        <v-btn v-if="open" small class="primary" title="Reroute" @click.stop="$emit('reroute', item)">
                            Reroute
                        </v-btn>
                    </template>
                    <template v-slot:listItem="listItem">
                        <v-list-item-content>
                            <v-list-item-title>{{ listItem.item.courierLocation | toFamiliarLocationLine }}</v-list-item-title>
                            

                            <v-list-item-subtitle v-if="currentMovement === listItem.item">
                                <BT-Toggle
                                    v-model="listItem.item.isFixed"
                                    falseText="Relative"
                                    trueText="Fixed" />
                                
                                <BT-Chips-Menu
                                    v-if="listItem.item.isFixed"
                                    v-model="listItem.item.weekdays"
                                    column
                                    isEditing
                                    :items="weekdayOptions()"
                                    multiple
                                    returnCSV
                                    xSmall />

                                <BT-Time-Picker
                                    v-if="listItem.item.isFixed"
                                    v-model="listItem.item.minuteOfDay"
                                    label="Time Of Day"
                                    returnMinuteOfDay
                                    :isHourly="false" />
                                
                                <v-checkbox
                                    v-if="listItem.item.sortOrder == 1"
                                    v-model="item.startCanDropoff"
                                    dense
                                    hide-details
                                    label="Clients can dropoff" />

                                <v-checkbox
                                    v-if="listItem.item.sortOrder == item.movements.length"
                                    v-model="item.endCanPickup"
                                    dense
                                    hide-details
                                    label="Clients can pickup" />
                                    
                                <v-checkbox
                                    v-if="listItem.item.sortOrder == item.movements.length"
                                    v-model="item.endCanDropoff"
                                    dense
                                    hide-details
                                    label="Can dropoff at clients'" />
                                
                                <v-checkbox
                                    v-model="listItem.item.isAutoDeliver"
                                    dense
                                    hide-details
                                    label="Auto Complete Delivery" />
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-else>
                                <div v-if="listItem.item.isFixed">
                                    Weekdays: {{ listItem.item.weekdays | toPrettyCSV }}<br />
                                    Time: {{ listItem.item.minuteOfDay | toTimeOfDay }}
                                </div>
                                <div v-else>
                                    Arrive: +{{ listItem.item.minutesToArrival | toDisplayNumber }} mins<br />
                                    Depart: +{{ listItem.item.minutesToDeparture | toDisplayNumber }} mins
                                </div>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </BT-Blade-Expansion-Items>
                <BT-Blade-Expansion-Items
                    v-if="item.id != null"
                    canDelete
                    :canExportCSV="false"
                    :canRefresh="false"
                    :canSelect="false"
                    :items="fromPointers"
                    navigation="route-pointers"
                    :onFilter="pointers => { return filterRoutePointers(pointers, item) }"
                    showList
                    title="Coverage From"
                    @select="selectPointer">
                    <template v-slot:actions="{ open }">
                        <BT-Menu
                            v-if="open"
                            icon="mdi-plus"
                            :offset-x="false"
                            openOnHover
                            small
                            title="New Pointer">
                            <template v-slot>
                                <v-list-item @click.stop="addRoutePointer(item, false, 'boundary')">
                                    <v-list-item-content>New Boundary</v-list-item-content>
                                </v-list-item>
                                <v-list-item @click.stop="addRoutePointer(item, false, 'location')">
                                    <v-list-item-content>Add My Location</v-list-item-content>
                                </v-list-item>
                                <v-list-item @click.stop="addRoutePointer(item, false, 'customer')">
                                    <v-list-item-content>Add Customer Location</v-list-item-content>
                                </v-list-item>
                            </template>
                        </BT-Menu>
                        <!-- <v-btn v-if="open" small icon @click.stop="addRoutePointer(item, false)">
                            <v-icon small>mdi-plus</v-icon>
                        </v-btn> -->
                    </template>
                    <template v-slot:listItem="{ item, deleteItem, save }">
                        <v-list-item-avatar class="mx-0 px-0">
                            <v-slide-x-transition hide-on-leave>
                                <v-btn v-if="item.id == null || $BlitzIt.tracker.isChanged(item)" icon @click.stop="save(item)">
                                    <v-icon>mdi-content-save</v-icon>
                                </v-btn>
                                <v-btn v-else small icon @click.stop="item.showArea = !item.showArea" title="Show Area">
                                    <v-icon small :color="item.showArea && containsWeekday(item.weekdays, currentWeekday) ? item.color : 'grey'">mdi-circle</v-icon>
                                </v-btn>
                            </v-slide-x-transition>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title v-if="item.location == null">
                                <v-text-field
                                    v-if="currentPointer === item && item.location == null"
                                    @click.stop
                                    hide-details
                                    single-line
                                    v-model="item.pointerName"
                                    label="Pointer Name" />
                                <span v-else-if="item.location == null">{{ item.pointerName || '(Pointer Name)' }}</span>
                                <span v-else>{{ item.location.companyAccount.companyName }}</span>
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="item.location != null">{{ item.location | toLocationLine }}</v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <BT-Chips-Menu
                                    v-model="item.weekdays"
                                    :canOpen="currentPointer === item"
                                    column
                                    isEditing
                                    :items="weekdayOptions()"
                                    multiple
                                    returnCSV
                                    xSmall />
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn small icon @click="deletePointer(item, deleteItem)">
                                <v-icon small class="error--text">mdi-delete</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </template>
                </BT-Blade-Expansion-Items>
                <BT-Blade-Expansion-Items
                    v-if="item.id != null"
                    canDelete
                    :canExportCSV="false"
                    :canRefresh="false"
                    :canSelect="false"
                    :items="toPointers"
                    navigation="route-pointers"
                    :onFilter="pointers => { return filterRoutePointers(pointers, item) }"
                    showList
                    title="Coverage To"
                    @select="selectPointer">
                    <template v-slot:actions="{ open }">
                        <BT-Menu
                            v-if="open"
                            icon="mdi-plus"
                            :offset-x="false"
                            openOnHover
                            small
                            title="New Pointer">
                            <template v-slot>
                                <v-list-item @click.stop="addRoutePointer(item, true, 'boundary')">
                                    <v-list-item-content>New Boundary</v-list-item-content>
                                </v-list-item>
                                <v-list-item @click.stop="addRoutePointer(item, true, 'location')">
                                    <v-list-item-content>Add My Location</v-list-item-content>
                                </v-list-item>
                                <v-list-item @click.stop="addRoutePointer(item, true, 'courier')">
                                    <v-list-item-content>Add Courier Location</v-list-item-content>
                                </v-list-item>
                                <v-list-item @click.stop="addRoutePointer(item, true, 'customer')">
                                    <v-list-item-content>Add Customer Location</v-list-item-content>
                                </v-list-item>
                            </template>
                        </BT-Menu>
                        <!-- <v-btn v-if="open" small icon @click.stop="addRoutePointer(item, true)">
                            <v-icon small>mdi-plus</v-icon>
                        </v-btn> -->
                    </template>
                    <template v-slot:listItem="{ item, deleteItem, save }">
                        <v-list-item-avatar class="mx-0 px-0">
                            <v-slide-x-transition hide-on-leave>
                                <v-btn v-if="item.id == null || $BlitzIt.tracker.isChanged(item)" icon @click.stop="save(item)">
                                    <v-icon>mdi-content-save</v-icon>
                                </v-btn>
                                <v-btn v-else small icon @click.stop="item.showArea = !item.showArea" title="Show Area">
                                    <v-icon small :color="item.showArea && containsWeekday(item.weekdays, currentWeekday) ? item.color : 'grey'">mdi-circle</v-icon>
                                </v-btn>
                            </v-slide-x-transition>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title v-if="item.location == null">
                                <v-text-field
                                    v-if="currentPointer === item && item.location == null"
                                    @click.stop
                                    hide-details
                                    single-line
                                    v-model="item.pointerName"
                                    label="Pointer Name" />
                                <span v-else-if="item.location == null">{{ item.pointerName || '(Pointer Name)' }}</span>
                                <span v-else-if="item.location.companyAccount != null">{{ item.location.companyAccount.companyName }}</span>
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="item.location != null">{{ item.location | toFamiliarLocationLine }}</v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <BT-Chips-Menu
                                    v-model="item.weekdays"
                                    :canOpen="currentPointer === item"
                                    column
                                    isEditing
                                    :items="weekdayOptions()"
                                    multiple
                                    returnCSV
                                    xSmall />
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row class="ma-0 pa-0">
                                <v-btn v-if="currentPointer === item && item.boundary != null" small icon @click.stop="$emit('match')" title="Match Area">
                                    <v-icon small>mdi-vector-selection</v-icon>
                                </v-btn>
                                <v-btn small icon @click="deletePointer(item, deleteItem)">
                                    <v-icon small class="error--text">mdi-delete</v-icon>
                                </v-btn>
                            </v-row>
                        </v-list-item-action>
                    </template>
                </BT-Blade-Expansion-Items>
            </v-expansion-panels>
        </template>
    </BT-Blade-Item>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Route-Blade',
    components: {
        BTChipsMenu: () => import('~components/BT-Chips-Menu.vue'),
        BTMenu: () => import('~components/BT-Menu.vue'),
        BTTimePicker: () => import('~components/BT-Time-Picker.vue'),
        BTToggle: () => import('~components/BT-Toggle.vue')
    },
    data: function() {
        return {
            colorRoute: '#741b47',
            currentMovement: null,
            currentPointer: null,
            isRouting: false,
            loadingMsg: null,
            panelV: [0,2],
            // weekdayOptions: ['Always', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
        center: null,
        currentWeekday: null,
        routePointers: null
    },
    computed: {
        fromPointers() {
            return this.routePointers.filter(z => !z.isDestination);
        },
        toPointers() {
            return this.routePointers.filter(z => z.isDestination);
        }
    },
    methods: {
        async addRoutePointer(route, isDestination, type) {
            if (route == null || route.id == null || type == null) {
                return;
            }

            var location = null;
            if (type == 'location') {
                location = await this.$selectItem({ 
                    navigation: 'locations', 
                    itemText: 'locationName'
                });
                
                if (location == null) {
                    return;
                }
            }
            else if (type == 'customer') {
                var c = await this.$selectItem({ navigation: 'customers', itemText: 'buyer.companyName', params: { properties: 'ID,Buyer' } });
                if (c == null) {
                    return;
                }
                
                var policy = await this.$BlitzIt.store.get('suppliers', c.id, null, false, null, '/Locations/Locations');
                if (policy.possibleLocations.length > 1) {
                    location = await this.$selectItemFrom({ items: policy.possibleLocations, textFilter: 'toFamiliarLocationLine' });
                }
                else if (policy.possibleLocations.length == 1) {
                    location = policy.possibleLocations[0];
                }
                else {
                    return;
                }
                
                if (location == null) {
                    return;
                }
            }
            else if (type == 'courier') {
                var courierLocations = await this.$BlitzIt.store.getAll('couriers', null, false, null, '/getAll/CourierLocations');
                if (courierLocations.length == 0) {
                    return;
                }
                else if (courierLocations.length == 1) {
                    location = courierLocations[0];
                }
                else {
                    location = await this.$selectItemFrom({ items: courierLocations, textFilter: 'toFamiliarLocationLine'});
                }

                if (location == null) {
                    return;
                }
            }

            var newPointer = {
                id: null,
                rowVersion: null,
                pointerName: (isDestination ? 'To' : 'From') + ' Area ' + (this.routePointers.length + 1).toString(),
                isDestination: isDestination,
                boundary: location == null ? this.getAreaAround(this.center, 0.5) : null,
                courierRouteID: route.id,
                weekdays: this.currentWeekday,
                locationID: location != null ? location.id : null,
                location: location != null ? location : null
            };

            newPointer = this.$BlitzIt.tracker.track(newPointer, {
                trackProps: ['id', 'rowVersion', 'pointerName', 'isDestination', 'boundary', 'weekdays'],
                assignIgnored: { showArea: true, color: this.colorRoute }
            });

            this.routePointers.unshift(newPointer);
        },
        async deletePointer(pointer, deleteCallback) {
            if (pointer.id != null) {
                await deleteCallback(pointer);
            }

            var ind = this.routePointers.findIndex(x => x._btID === pointer._btID);
            if (ind >= 0) {
                this.routePointers.splice(ind, 1);
            }
        },
        filterRoutePointers(pointers, route) {
            var l = route != null && route.id != null ? pointers.filter(z => z.courierRouteID == route.id) : [];
            l.sort(
                firstBy(x => x.locationID != null)
                .thenBy(x => x.location != null && x.location.companyAccount != null ? x.location.companyAccount.companyName : 'aaaaaaaa'));
            return l;
        },
        async pullRoute(route) {
            if (route != null) {
                route.movements = route.movements.orderBy('sortOrder');

                //load all locations
                var locations = await this.$BlitzIt.store.getAll('locations');
                var courierLocations = await this.$BlitzIt.store.getAll('couriers', null, false, null, '/getAll/CourierLocations');

                this.locations = [];
                locations.forEach(location => {
                    this.locations.push({
                        id: null,
                        courierLocationID: location.id,
                        courierLocation: location,
                        minutesToArrival: 0,
                        minutesToDeparture: 0,
                        isAutoDeliver: false,
                        sortOrder: null
                    });
                });

                courierLocations.forEach(location => {
                    this.locations.push({
                        id: null,
                        courierLocationID: location.id,
                        courierLocation: location,
                        minutesToArrival: 0,
                        minutesToDeparture: 0,
                        isAutoDeliver: false,
                        sortOrder: null
                    });
                });

                if (this.isLengthyArray(route.movements)) {
                    route.movements.forEach(x => {
                        var l = this.locations.find(y => y.courierLocationID == x.courierLocationID);
                        if (l != null) {
                            l.id = x.id;
                            l.sortOrder = x.sortOrder.toString();
                            l.minutesToArrival = x.minutesToArrival;
                            l.minutesToDeparture = x.minutesToDeparture;
                            l.isAutoDeliver = x.isAutoDeliver;
                        }
                        else {
                            this.locations.push(x);
                        }
                    });
                }
            }

            return route;
        },
        selectMovement(item) {
            this.currentMovement = item;
            this.$emit('selectMovement', item);
        },
        selectPointer(item) {
            this.currentPointer = item;
            this.$emit('selectPointer', item);
        }
    }
}
</script>